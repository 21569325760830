const images = {
  'ad' : require('./ad.png'),
  'ae' : require('./ae.png'),
  'af' : require('./af.png'),
  'ag' : require('./ag.png'),
  'ai' : require('./ai.png'),
  'al' : require('./al.png'),
  'am' : require('./am.png'),
  'ao' : require('./ao.png'),
  'aq' : require('./aq.png'),
  'ar' : require('./ar.png'),
  'as' : require('./as.png'),
  'at' : require('./at.png'),
  'au' : require('./au.png'),
  'aw' : require('./aw.png'),
  'ax' : require('./ax.png'),
  'az' : require('./az.png'),
  'ba' : require('./ba.png'),
  'bb' : require('./bb.png'),
  'bd' : require('./bd.png'),
  'be' : require('./be.png'),
  'bf' : require('./bf.png'),
  'bg' : require('./bg.png'),
  'bh' : require('./bh.png'),
  'bi' : require('./bi.png'),
  'bj' : require('./bj.png'),
  'bl' : require('./bl.png'),
  'bm' : require('./bm.png'),
  'bn' : require('./bn.png'),
  'bo' : require('./bo.png'),
  'bq' : require('./bq.png'),
  'br' : require('./br.png'),
  'bs' : require('./bs.png'),
  'bt' : require('./bt.png'),
  'bv' : require('./bv.png'),
  'bw' : require('./bw.png'),
  'by' : require('./by.png'),
  'bz' : require('./bz.png'),
  'ca' : require('./ca.png'),
  'cc' : require('./cc.png'),
  'cd' : require('./cd.png'),
  'cf' : require('./cf.png'),
  'cg' : require('./cg.png'),
  'ch' : require('./ch.png'),
  'ci' : require('./ci.png'),
  'ck' : require('./ck.png'),
  'cl' : require('./cl.png'),
  'cm' : require('./cm.png'),
  'cn' : require('./cn.png'),
  'co' : require('./co.png'),
  'cr' : require('./cr.png'),
  'cu' : require('./cu.png'),
  'cv' : require('./cv.png'),
  'cw' : require('./cw.png'),
  'cx' : require('./cx.png'),
  'cy' : require('./cy.png'),
  'cz' : require('./cz.png'),
  'de' : require('./de.png'),
  'dj' : require('./dj.png'),
  'dk' : require('./dk.png'),
  'dm' : require('./dm.png'),
  'do' : require('./do.png'),
  'dz' : require('./dz.png'),
  'ec' : require('./ec.png'),
  'ee' : require('./ee.png'),
  'eg' : require('./eg.png'),
  'eh' : require('./eh.png'),
  'er' : require('./er.png'),
  'es-ct' : require('./es-ct.png'),
  'es' : require('./es.png'),
  'et' : require('./et.png'),
  'eu' : require('./eu.png'),
  'fi' : require('./fi.png'),
  'fj' : require('./fj.png'),
  'fk' : require('./fk.png'),
  'fm' : require('./fm.png'),
  'fo' : require('./fo.png'),
  'fr' : require('./fr.png'),
  'ga' : require('./ga.png'),
  'gb-eng' : require('./gb-eng.png'),
  'gb-nir' : require('./gb-nir.png'),
  'gb-sct' : require('./gb-sct.png'),
  'gb-wls' : require('./gb-wls.png'),
  'gb' : require('./gb.png'),
  'gd' : require('./gd.png'),
  'ge' : require('./ge.png'),
  'gf' : require('./gf.png'),
  'gg' : require('./gg.png'),
  'gh' : require('./gh.png'),
  'gi' : require('./gi.png'),
  'gl' : require('./gl.png'),
  'gm' : require('./gm.png'),
  'gn' : require('./gn.png'),
  'gp' : require('./gp.png'),
  'gq' : require('./gq.png'),
  'gr' : require('./gr.png'),
  'gs' : require('./gs.png'),
  'gt' : require('./gt.png'),
  'gu' : require('./gu.png'),
  'gw' : require('./gw.png'),
  'gy' : require('./gy.png'),
  'hk' : require('./hk.png'),
  'hm' : require('./hm.png'),
  'hn' : require('./hn.png'),
  'hr' : require('./hr.png'),
  'ht' : require('./ht.png'),
  'hu' : require('./hu.png'),
  'id' : require('./id.png'),
  'ie' : require('./ie.png'),
  'il' : require('./il.png'),
  'im' : require('./im.png'),
  'in' : require('./in.png'),
  'io' : require('./io.png'),
  'iq' : require('./iq.png'),
  'ir' : require('./ir.png'),
  'is' : require('./is.png'),
  'it' : require('./it.png'),
  'je' : require('./je.png'),
  'jm' : require('./jm.png'),
  'jo' : require('./jo.png'),
  'jp' : require('./jp.png'),
  'ke' : require('./ke.png'),
  'kg' : require('./kg.png'),
  'kh' : require('./kh.png'),
  'ki' : require('./ki.png'),
  'km' : require('./km.png'),
  'kn' : require('./kn.png'),
  'kp' : require('./kp.png'),
  'kr' : require('./kr.png'),
  'kw' : require('./kw.png'),
  'ky' : require('./ky.png'),
  'kz' : require('./kz.png'),
  'la' : require('./la.png'),
  'lb' : require('./lb.png'),
  'lc' : require('./lc.png'),
  'li' : require('./li.png'),
  'lk' : require('./lk.png'),
  'lr' : require('./lr.png'),
  'ls' : require('./ls.png'),
  'lt' : require('./lt.png'),
  'lu' : require('./lu.png'),
  'lv' : require('./lv.png'),
  'ly' : require('./ly.png'),
  'ma' : require('./ma.png'),
  'mc' : require('./mc.png'),
  'md' : require('./md.png'),
  'me' : require('./me.png'),
  'mf' : require('./mf.png'),
  'mg' : require('./mg.png'),
  'mh' : require('./mh.png'),
  'mk' : require('./mk.png'),
  'ml' : require('./ml.png'),
  'mm' : require('./mm.png'),
  'mn' : require('./mn.png'),
  'mo' : require('./mo.png'),
  'mp' : require('./mp.png'),
  'mq' : require('./mq.png'),
  'mr' : require('./mr.png'),
  'ms' : require('./ms.png'),
  'mt' : require('./mt.png'),
  'mu' : require('./mu.png'),
  'mv' : require('./mv.png'),
  'mw' : require('./mw.png'),
  'mx' : require('./mx.png'),
  'my' : require('./my.png'),
  'mz' : require('./mz.png'),
  'na' : require('./na.png'),
  'nc' : require('./nc.png'),
  'ne' : require('./ne.png'),
  'nf' : require('./nf.png'),
  'ng' : require('./ng.png'),
  'ni' : require('./ni.png'),
  'nl' : require('./nl.png'),
  'no' : require('./no.png'),
  'np' : require('./np.png'),
  'nr' : require('./nr.png'),
  'nu' : require('./nu.png'),
  'nz' : require('./nz.png'),
  'om' : require('./om.png'),
  'pa' : require('./pa.png'),
  'pe' : require('./pe.png'),
  'pf' : require('./pf.png'),
  'pg' : require('./pg.png'),
  'ph' : require('./ph.png'),
  'pk' : require('./pk.png'),
  'pl' : require('./pl.png'),
  'pm' : require('./pm.png'),
  'pn' : require('./pn.png'),
  'pr' : require('./pr.png'),
  'ps' : require('./ps.png'),
  'pt' : require('./pt.png'),
  'pw' : require('./pw.png'),
  'py' : require('./py.png'),
  'qa' : require('./qa.png'),
  're' : require('./re.png'),
  'ro' : require('./ro.png'),
  'rs' : require('./rs.png'),
  'ru' : require('./ru.png'),
  'rw' : require('./rw.png'),
  'sa' : require('./sa.png'),
  'sb' : require('./sb.png'),
  'sc' : require('./sc.png'),
  'sd' : require('./sd.png'),
  'se' : require('./se.png'),
  'sg' : require('./sg.png'),
  'sh' : require('./sh.png'),
  'si' : require('./si.png'),
  'sj' : require('./sj.png'),
  'sk' : require('./sk.png'),
  'sl' : require('./sl.png'),
  'sm' : require('./sm.png'),
  'sn' : require('./sn.png'),
  'so' : require('./so.png'),
  'sr' : require('./sr.png'),
  'ss' : require('./ss.png'),
  'st' : require('./st.png'),
  'sv' : require('./sv.png'),
  'sx' : require('./sx.png'),
  'sy' : require('./sy.png'),
  'sz' : require('./sz.png'),
  'tc' : require('./tc.png'),
  'td' : require('./td.png'),
  'tf' : require('./tf.png'),
  'tg' : require('./tg.png'),
  'th' : require('./th.png'),
  'tj' : require('./tj.png'),
  'tk' : require('./tk.png'),
  'tl' : require('./tl.png'),
  'tm' : require('./tm.png'),
  'tn' : require('./tn.png'),
  'to' : require('./to.png'),
  'tr' : require('./tr.png'),
  'tt' : require('./tt.png'),
  'tv' : require('./tv.png'),
  'tw' : require('./tw.png'),
  'tz' : require('./tz.png'),
  'ua' : require('./ua.png'),
  'ug' : require('./ug.png'),
  'um' : require('./um.png'),
  'un' : require('./un.png'),
  'us' : require('./us.png'),
  'uy' : require('./uy.png'),
  'uz' : require('./uz.png'),
  'va' : require('./va.png'),
  'vc' : require('./vc.png'),
  've' : require('./ve.png'),
  'vg' : require('./vg.png'),
  'vi' : require('./vi.png'),
  'vn' : require('./vn.png'),
  'vu' : require('./vu.png'),
  'wf' : require('./wf.png'),
  'ws' : require('./ws.png'),
  'xk' : require('./xk.png'),
  'ye' : require('./ye.png'),
  'yt' : require('./yt.png'),
  'za' : require('./za.png'),
  'zm' : require('./zm.png'),
  'zw' : require('./zw.png'),
};

export default images;