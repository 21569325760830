import '../assets/style.css';
import Header from "../components/header.component";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logOut, userRegister} from "../lib/reducers/User.reducer";

const RegisterScreen = () => {

  const [userName, setUserName] = useState('');
  const [userPass, setUserPass] = useState('');

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const navigate = useNavigate()

  const userRegistration = () => {
    if (userName && userPass) {
      dispatch(userRegister({userName, userPass}))
      setErrorMessage(null)
    }else {
      setErrorMessage("Login and password can't be empty")
    }

  }
  useEffect(()=>{
    dispatch(logOut(3))
  },[])

  useEffect(() => {
    if (user.success === 0) {
      logError()
    }
    if (user.success === 1){
      navigate('/battles', {replace: true})
    }
  }, [user])

  console.log('registration screen', user)

  const [errorMessage, setErrorMessage] = React.useState("");
  const logError = () => {
    setErrorMessage(user.error)
  }

  return (
    <div className="auth-screen">
      <Header/>
      <main className="container flex-center flex-column">
        <div>
          <h1>Registration</h1>
        </div>
        {errorMessage &&
        <div className="wrong-login">
          {errorMessage}
        </div>
        }
        <div className="input-block flex-center flex-column reg-input-block">
          <input type="text" placeholder="Username" value={userName} onChange={(e)=> setUserName(e.target.value)}/>
          <input type="password" placeholder="Password" value={userPass} onChange={(e)=> setUserPass(e.target.value)}/>
        </div>
        <div className="btn-block flex-center flex-column">
          <div>
            <div onClick={()=> userRegistration()}>
              <div className="login-btn">Register</div>
            </div>
          </div>
          <div>
            <Link to={"/login"}>
              <div className="reg-btn">Cancel</div>
            </Link>
          </div>
        </div>
        <div>
          <h3>This is <span className="extra-orange">NOT</span> mobile app account</h3>
        </div>
      </main>
      <footer className="container flex-center">
        <div>
          <h3>
            SIA "TenPinCams"<br/>
            Registration Nr. 42103088398<br/>
            Address: Jūrmala, Konkordijas iela 25, LV-2015, Latvia<br/>
            E-mail: <a href="mailto:info@tenpincam.com">info@tenpincam.com</a><br/>
            Phone: +37129215590<br/>
          </h3>
        </div>
      </footer>
    </div>)
}

export default RegisterScreen;
