import {put, takeEvery, call, spawn, select, take} from 'redux-saga/effects'
import {GET_BATTLE_LIST, getBattleList, setBattleList} from "./reducers/battleList.reducer";
import axios from "axios";
import {LOG_IN_FETCH, LOG_OUT_FETCH, logIn, logInFetch, logOut, USER_REGISTER} from "./reducers/User.reducer";
import {_socketSaga} from "./Socket";
import {lazySocket} from "./lazySocket";
import {GET_SCORE, setSquadScore} from "./reducers/Squad.reducer";
import {config} from "../config";

// const server = 'http://localhost:3001'
const server = config.FETCH.fullUrl




function* _getBattleList (){
try {
  const {data} = yield call(axios.get, `${server}/api/battles`)
  console.log('IN _GET BATTLE LISt', data)
 if (data.success){
   yield put(setBattleList(data.squads))
 }

}catch (e){
  console.log('_getBattleList', e)
}
}

function* _logIn (params){

  const {userName, userPass} = params.payload

  try {
    const {data} = yield call(axios.post, `${server}/api/login`, {
      method: 'POST',
      mode: 'cors',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        login: userName,
        password: userPass
      })
    })
    if (data.success){
      yield put(logIn(data))
      const session = data.session
      yield spawn(_socketSaga, {
        eventName: 'auth',
        params: {tpc_userid: session.tpc_userid, sessionkey: session.sessionkey},
        // params: {id: 2, sessionkey: '31f5ec41708eb7d14be479e2c2b4141a'}
      });
    } else (
      yield put(logIn(data))
    )

  }catch (e){
    console.error('_LOG_IN ERROR', e)
  }
}

function* _userReg(params) {
  const {userName, userPass} = params.payload
  try {
    const {data} = yield call(axios.post, `${server}/api/registration`, {
        method: 'POST',
        mode: 'cors',
        headers: { "Accept": "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({
          login: userName,
          password: userPass
        })
      })

    console.log('RESULT', data)


    if (data.success){
      yield put(logInFetch({userName, userPass}))
    }else (
      yield put(logIn(data))
    )
  }catch (e){
    console.error('_LOG_IN ERROR', e)
  }
}

function* _logOut(params) {
  try {
    yield put(logOut())
    lazySocket.socket && lazySocket.socket.disconnect()
  }catch (e) {
    console.error('_logOut ERROR: ', e)
  }
}

function* _setScore(params) {
  try {
    const {data} = yield call(axios.post, `${server}/api/squad`, {
      method: 'POST',
      mode: 'cors',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        squadID: params.payload
      })
    })

    console.log('RESULT', data)

    if (data.success){
      yield put(setSquadScore(data.result))
    }else (
      yield put(setSquadScore(data))
    )
  }catch (e){
    console.error('_LOG_IN ERROR', e)
  }
}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

function* startSaga() {
  try {
    console.log("START SAGA")
    yield take('persist/REHYDRATE')
    // yield call(delay, 400)
    yield put(getBattleList())
    const session = yield select(state => state.user.session)
    if (session && session.sessionkey && session.tpc_userid) {
      yield spawn(_socketSaga, {
        params: {tpc_userid: session.tpc_userid, sessionkey: session.sessionkey},
      })
    }
  } catch (e) {
    console.log('startSaga error', e)
  }
}


export function* battleListWatcher (){
  yield call(startSaga)
  yield takeEvery(GET_BATTLE_LIST, _getBattleList)
  yield takeEvery(LOG_IN_FETCH, _logIn)
  yield takeEvery(USER_REGISTER, _userReg)
  yield takeEvery(LOG_OUT_FETCH, _logOut)
  yield takeEvery(GET_SCORE, _setScore)
}
