import logo from '../img/Logo-TenPinCam.png'

const Header = () => {
  return (
    <header className="container flex-center">
      <div>
        <img src={logo}/>
      </div>
    </header>
  )
};

export default Header;