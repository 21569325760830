import '../assets/battlesStyle.css'
import '../assets/gameStyle.css'
import NoGame from '../img/No-Games.png'
import PlayerCard from "../components/playerCard.component";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBattleList} from "../lib/reducers/battleList.reducer";
import {logOutFetch} from "../lib/reducers/User.reducer";
import {Link, useNavigate} from "react-router-dom";
import {clearSquadScore, getSquadScore} from "../lib/reducers/Squad.reducer";
import testImg from "../img/testman.png"
import getFlagPng from "../flags/index";
import * as countryFlags from "../flags/1x1png";
import {type} from "@testing-library/user-event/dist/type";
import camera from "../img/image5.2.jpg"
import Score from "../components/firstScoreItem";
import SecondScore from "../components/secondScoreItem";
import ReactPlayer from "react-player";
import {lazySocket} from "../lib/lazySocket";
import {store} from "../lib/store";


const BattlesScreen = () => {

  const dispatch = useDispatch()
  const squads = useSelector(state => state.battleList.squads)
  const fetch = useSelector(state => state.battleList.fetching)
  const user = useSelector(state => state.user)
  const navigate = useNavigate()
  const squadKey = localStorage.getItem('squadKey')
  const squadId = JSON.parse(squadKey)
  const squad = useSelector(state => state.squad)
  const competitors = squad.competitors || []
  const data = competitors[0]?.games[0]?.score || []
  const data2 = competitors[1]?.games[0]?.score || []

  let owner = competitors?.find(com => com.userid = squad.createdby)
  const [turn, setTurn] = useState(owner);
  const [currentFrameOwner, setCurrentFrameOwner] = useState(0);
  const [currentFrameOpponent, setCurrentFrameOpponent] = useState(0);
  let currentGame = competitors[0]?.games.length - 1

  useEffect(() => {
    if (user.login){
    dispatch(getBattleList())
    }else {
      navigate('/login')
    }
  }, [])

  const logOut = () => {
    navigate('/login', { replace: true })
    dispatch(logOutFetch(4))
  }

  console.log('SQUADS', squads)
  console.log('SQUAD', squad)
  // console.log("no-games", squads.length)

  return (
    <div className="battles-screen">
      <main>
        {squads.length === 0 && <div className="no-game-window">
          <img src={NoGame}/>
        </div>}
        <div className="card-bloc">
          {squads?.map((squad, index) => <PlayerCard squad={squad} key={index}/>)}
        </div>
        {/*<div className="exit-button">*/}
        {/*  <div onClick={() => logOut()}>*/}
        {/*    Exit*/}
        {/*  </div>*/}
        {/*</div>*/}
      </main>
    </div>
  )
}

export default BattlesScreen;
