
export const GET_BATTLE_LIST = 'GET_BATTLE_LIST'
export const SET_BATTLE_LIST = 'SET_BATTLE_LIST'

const initialState = {
  squads: [],
  fetching: false
}

export default  function battleListReducer(state = initialState, action){
  switch (action.type) {
    case GET_BATTLE_LIST:
      return {...state, fetching: true}

    case SET_BATTLE_LIST:
      return {squads: [...action.payload], fetching: false}

    default:
      return state
  }
}


export const getBattleList = () => ({type: GET_BATTLE_LIST})
export const setBattleList = (props) => ({type: SET_BATTLE_LIST, payload: props})
