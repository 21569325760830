import '../assets/style.css';
import React from "react";
import Header from "../components/header.component";
import {Link, Redirect, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logInFetch, logOut} from "../lib/reducers/User.reducer";

const AuthScreen = () => {
  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");
  const user = useSelector(state => state.user);
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const LogIn = () => {
    if (userName && userPass) {
      dispatch(logInFetch({userName, userPass}))
      setErrorMessage(null)
    } else {
      setErrorMessage("Login and password can't be empty")
    }

  }
  // useEffect(() => {
  //   dispatch(logOut(1))
  // }, [])

  useEffect(() => {
    if (user.success === 0) {
      logError()
    }
    if (user.success){
      navigate('/battles', { replace: true })
    }
  }, [user])

  // console.log('USER', user)

  const [errorMessage, setErrorMessage] = React.useState("");
  const logError = () => {
    setErrorMessage("Wrong login or password")
  }


  return (
    <div className="auth-screen">
      <Header/>
      <main className="container flex-center flex-column">
        <div>
          <h1>Log in</h1>
        </div>
        {errorMessage &&
        <div className="wrong-login">
          {errorMessage}
        </div>
        }
        <div className="input-block flex-center flex-column">
          <input type="text" placeholder="Username" value={userName} onChange={(e) => setUserName(e.target.value)}/>
          <input type="password" placeholder="Password" value={userPass} onChange={(e) => setUserPass(e.target.value)}/>
        </div>
        <div className="forgot-pass">
          <a href="#"><h3>Forgot password?</h3></a>
        </div>
        <div className="btn-block flex-center flex-column">
          <div>
            <div onClick={() => LogIn()}>
              <div className="login-btn">Log in</div>
            </div>
          </div>
          <div>
            <Link to={"/register"}>
              <div className="reg-btn">Registration</div>
            </Link>
          </div>
        </div>
        <div>
          <h3>This is <span className="extra-orange">NOT</span> mobile app account</h3>
        </div>
      </main>
      <footer className="container flex-center">
        <div>
          <h3>
            SIA "TenPinCams"<br/>
            Registration Nr. 42103088398<br/>
            Address: Jūrmala, Konkordijas iela 25, LV-2015, Latvia<br/>
            E-mail: <a href="mailto:info@tenpincam.com">info@tenpincam.com</a><br/>
            Phone: +37129215590<br/>
          </h3>
        </div>
      </footer>
    </div>
  )
}

export default AuthScreen;
