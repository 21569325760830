import {Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {lazySocket} from "../lib/lazySocket";
import testImg from "../img/testman.png";
import * as countryFlags from "../flags/1x1png";
import React, {useState} from "react";
import {clearSquadScore, getSquadScore} from "../lib/reducers/Squad.reducer";
import {useDispatch, useSelector} from "react-redux";
import {config} from "../config";


const PlayerCard = ({squad}) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const competitors = squad.competitors || []

  let owner = competitors?.find(com => com.userid = squad.createdby)
  const [turn, setTurn] = useState(owner);
  const [currentFrameOwner, setCurrentFrameOwner] = useState(0);
  const [currentFrameOpponent, setCurrentFrameOpponent] = useState(0);
  let currentGame = competitors[0]?.games.length - 1

  const goBattlesList = () => {
    lazySocket.socket?.emit('leave-squad', squad.id)
    dispatch(clearSquadScore())
    navigate('/battles', {replace: true})
  }

  let opponent;
  if (competitors.length > 1) opponent = competitors.find(com => com.userid != squad.createdby)

  const avgOwner = +owner?.avgScore
  const avgOpponent = +opponent?.avgScore
  let totalOwner = owner?.games[currentGame]?.score[currentFrameOwner - 1]?.score
  let hdcOwner = totalOwner + owner?.hdc

  let totalOpponent = opponent?.games[currentGame]?.score[currentFrameOpponent - 1]?.score
  let hdcOpponent = totalOpponent + opponent?.hdc

  if (currentFrameOwner === 0 && owner?.games[currentGame]?.score[9]?.score) {
    totalOwner = owner?.games[currentGame]?.score[9]?.score
    hdcOwner = totalOwner  + owner?.hdc
  } else if (currentFrameOwner === 0 && !owner?.games[currentGame]?.score[9]?.score){
    totalOwner = owner?.games[currentGame]?.score[0]?.score
    hdcOwner = totalOwner  + owner?.hdc
  }

  if (currentFrameOpponent === 0 && opponent?.games[currentGame]?.score[9]?.score){
    totalOpponent = opponent?.games[currentGame]?.score[9]?.score
    hdcOpponent = totalOpponent + opponent?.hdc
  } else if (currentFrameOpponent === 0 && !opponent?.games[currentGame]?.score[9]?.score){
    totalOpponent = opponent?.games[currentGame]?.score[0]?.score
    hdcOpponent = totalOpponent + opponent?.hdc
  }


  if (hdcOpponent > 300) {
    hdcOpponent = 300
  }

  if (hdcOwner > 300) {
    hdcOwner = 300
  }

  const flagOwner = owner?.country_live.toLowerCase()
  const flagOpponent = opponent?.country_live.toLowerCase()

  console.log('COMPETITORS', squad)

  return (
    <div onClick={() => {
      localStorage.setItem('squadKey', JSON.stringify(squad.sqaudid));
      navigate('/game', {replace: true})
      try {
        console.log('socket', lazySocket.socket)
        lazySocket.socket.emit('join-squad', squad.sqaudid)
      } catch (e) {
        console.log('error', e)
      }
    }}>
      <div className="player-card">
        <div className="card-head">
          <span>#{squad.sqaudid}</span><span>{squad.name}</span>
        </div>
        <div className="card-main">
          <div className="battler-players-card">
            {/*First player*/}
            <div className="user-avatar">
              <div className="user-avatar-info">
                <div>{owner?.firstName} {owner?.lastName}</div>
                <div>{owner?.login}</div>
                {/*<div>* * * * * 3.0</div>*/}
              </div>
              <div className="user-avatar-img">
                <img src={`${config.FETCH.img}/${owner?.photo}`}/>
                <svg className="circle" viewBox="0 0 70 70">
                  <circle cx="50%" cy="50%" r="48%" stroke="#F65932" fill="transparent" strokeWidth="3"/>
                </svg>
                <div className="user-avg-score">
                  {avgOwner.toFixed()}
                </div>
                <div className="user-flag">
                  <img src={countryFlags.default[flagOwner]} alt=""/>
                </div>
              </div>
              {opponent ?
                <div className="games-win">{owner?.gameswin} </div>
                :
                <div></div>
              }
            </div>
            {/*Second player*/}
            {opponent && <div className="user-avatar">
              <div className="user-avatar-info">
                <div>{opponent?.firstName} {opponent?.lastName}</div>
                <div>{opponent?.login}</div>
                {/*<div>* * * * * 3.0</div>*/}
              </div>
              <div className="user-avatar-img">
                <img src={`${config.FETCH.img}/${opponent?.photo}`}/>
                <svg className="circle" viewBox="0 0 70 70">
                  <circle cx="50%" cy="50%" r="48%" stroke="#35C844" fill="transparent" strokeWidth="3"/>
                </svg>
                <div className="user-avg-score">
                  {avgOpponent.toFixed()}
                </div>
                <div className="user-flag">
                  <img src={countryFlags.default[flagOpponent]} alt=""/>
                </div>
              </div>
              <div className="games-win2">:{opponent?.gameswin}</div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
};

export default PlayerCard;
