import * as ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthScreen from "./screen/auth.screen";
import RegisterScreen from "./screen/register.screen";
import BattlesScreen from "./screen/battles.screen";
import GameScreen from "./screen/game.screen";

function App() {
  return (
    <BrowserRouter>
      <AppRouter/>
    </BrowserRouter>
  );
}

const AppRouter = () => (
  <Routes>
    <Route path={"/"} element={<AuthScreen />} />
    <Route path={"/login"} element={<AuthScreen />} />
    <Route path="/register" element={<RegisterScreen />} />
    <Route path="/battles" element={<BattlesScreen />} />
    <Route path="/game" element={<GameScreen />} />
  </Routes>
)

export default App;
