import {Link} from "react-router-dom";

const Score = ({frame}) => {
  return (
    <div className="score-table-item score-table-item-2">
      <div>{frame.framenumber}</div>
      <div className="score-table-shoots">
        {frame.shot1 === -1 ? '' : frame.shot1 === 0 ? '- ' : frame.shot1 === 10 ? 'X ' : frame.shot1 + ' '}
        {frame.shot2 === -1 ? '' : frame.shot2 === 0 ? '- ' : frame.shot1 + frame.shot2 === 10 ? '/ ' : frame.shot2 === 10 ? 'X ' : frame.shot2 + ' '}
        {frame.shot3 === -1 ? '' : frame.shot3 === 0 ? '- ' : frame.shot2 + frame.shot3 === 10 ? '/ ' : frame.shot3 === 10 ? 'X' : frame.shot3}
      </div>
      <div>{frame.score}</div>
    </div>
  )
};

export default Score;