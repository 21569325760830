

export const LOG_IN = 'LOG_IN'
export const LOG_IN_FETCH = 'LOG_IN_FETCH'
export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_FETCH = 'LOG_OUT_FETCH'
export const USER_REGISTER = 'USER_REGISTER'


const initialState = {
  fetching: false
}


export default  function userReducer(state = initialState, action){
  switch (action.type) {
    case LOG_IN:
      return {...state, ...action.payload, fetching: false}

    case LOG_IN_FETCH:
      return {...state, fetching: true}

    case LOG_OUT:
      return {fetching: false}

    case LOG_OUT_FETCH:
      return {fetching: true}

    default:
      return state
  }
}

export const logIn = (props) => ({type: LOG_IN, payload: props})
export const logOut = (props) => ({type: LOG_OUT, payload: props})
export const logInFetch = (props) => ({type: LOG_IN_FETCH, payload: props})
export const logOutFetch = (props) => ({type: LOG_OUT_FETCH, payload: props})
export const userRegister = (props) => ({type: USER_REGISTER, payload: props})
