import openSocket from 'socket.io-client';
import {call, take, select, put} from "redux-saga/effects";
import {lazySocket} from './lazySocket'
import {GET_SCORE, getSquadScore} from "./reducers/Squad.reducer";
import {store} from "./store";
import {getBattleList} from "./reducers/battleList.reducer";
import {config} from "../config";
let socket;

export function unSubscribe() {
  // connection.close();
  console.log('Socket off');
}

export function _ConnectToSocket(params) {
  if (socket) {
    socket.close();
    if (typeof socket.destroy === 'function') {
      socket.destroy();
    }
    socket = null;
  }

  const { id: id, sessionkey, tpc_userid } = params.params;

  socket = openSocket(config.FETCH.socketUrl, {
    secure: false,
    rejectUnauthorized: false,
    reconnection: true,
    reconnectionDelay: 2000,
    transports: ['websocket'],
    query: { userid: tpc_userid, token: sessionkey },
  });
  lazySocket.socket = socket;

  socket.on('squadUpdate', (squad) => {
    store.dispatch(getSquadScore(squad.id))
    // console.log('squad', squad)
  })
  socket.on('getSquads', () => {
    console.log('NEW SQUAD')
    store.dispatch(getBattleList())
  })

  socket.on('connect', () => {
    let squadid = store.getState().squad.sqaudid
    console.log('SOCKET connected')
    if (squadid) socket.emit('join-squad', squadid)
  })
  socket.on('disconnect', (reason) => {
    console.log('SOCKET disconnected', { reason })
  })
  socket.on('connect_error', (reason) => {
    console.log('SOCKET connect_error', { reason })
  })
}


export function* _socketSaga(params) {

  yield call(_ConnectToSocket, params);
  // while (true) {
  //   const channelAction = yield take(channel);
  //   yield put(channelAction);
  //   // TODO: unsubscribe logout
  // }
}
