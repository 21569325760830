export const GET_SCORE = 'GET_SCORE'
export const SET_SCORE = 'SET_SCORE'
export const CLEAR_SCORE = 'CLEAR_SCORE'

const initialState = {
  competitors: [],
  fetching: false
}


export default  function squadReducer(state = initialState, action){
  switch (action.type) {
    case GET_SCORE:
      return {...state, fetching: true}

    case SET_SCORE:
      return {...action.payload, fetching: false}

    case CLEAR_SCORE:
      return {...initialState}

    default:
      return state
  }
}


export const getSquadScore = (props) => ({type: GET_SCORE, payload: props})
export const setSquadScore = (props) => ({type: SET_SCORE, payload: props})
export const clearSquadScore = (props) => ({type: CLEAR_SCORE, payload: props})
