import React, {useEffect, useState} from "react";
import '../assets/gameStyle.css'
import {Link, useNavigate} from "react-router-dom";
import camera from "../img/image5.2.jpg"
import Score from "../components/firstScoreItem";
import SecondScore from "../components/secondScoreItem";
import ReactPlayer from "react-player";
import {useDispatch, useSelector} from "react-redux";
import {getBattleList} from "../lib/reducers/battleList.reducer";
import {lazySocket} from "../lib/lazySocket";
import {clearSquadScore, getSquadScore} from "../lib/reducers/Squad.reducer";
import {store} from "../lib/store";
import testImg from "../img/testman.png"
import getFlagPng from "../flags/index";
import * as countryFlags from "../flags/1x1png";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import {config} from "../config";


const INITIAL_DATA = [
  {
    framenumber: 1,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 2,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 3,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 4,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 5,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 6,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 7,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 8,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 9,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  }, {
    framenumber: 10,
    shot1: '',
    shot2: '',
    shot3: '',
    score: '',
  },
]

const token = 'localhost'

const GameScreen = () => {

  const cameraName = `cam${camera.id}-1`;
  const uriLive = `https://tenpincam.com:8081/${cameraName}/index.m3u8?token=localhost`;
  const squadKey = localStorage.getItem('squadKey')
  const squadId = JSON.parse(squadKey)
  const squad = useSelector(state => state.squad)
  const competitors = squad.competitors || []
  const dispatch = useDispatch()
  const [cameras, setCameras] = useState({});
  const navigate = useNavigate()
  const [fetch, setFetch] = useState(squad.fetching)

  useEffect(() => {
    setFetch(squad.fetching)
  },[squad.fetching])

  const user = useSelector(state => state.user)


  const fotka = 'https://dev.tenpincam.com/img/user_1_1644480892614.jpeg?v=0.4765096793590258'
    // `https://dev.tenpincam.com/${competitors[0]?.photo}`

  // Логика для определения чей ход
  let owner = competitors?.find(com => com.userid = squad.createdby)
  const [turn, setTurn] = useState(owner);
  const [currentFrameOwner, setCurrentFrameOwner] = useState(0);
  const [currentFrameOpponent, setCurrentFrameOpponent] = useState(0);
  let currentGame = competitors[0]?.games.length - 1

  useEffect(() => {
    if (user.login){
      if (squadId) {
        dispatch(getSquadScore(squadId))
      } else {
        navigate('/battles')
      }
    }else {
      navigate('/login')
    }
  }, [])

  useEffect(()=>{
    setTurn(owner)
  },[owner])

  let opponent;
  if (competitors.length > 1) opponent = competitors.find(com => com.userid != squad.createdby)

  const getCurrentFrame = (curFrame, score) => {
    if (score) {
      const index = score.findIndex(frame => frame.shot1 === -1)
      return  index
    }
  }

  useEffect(() =>{
    setCurrentFrameOwner(getCurrentFrame(currentFrameOwner, owner?.games[currentGame]?.score))
    if (competitors?.length > 1) setCurrentFrameOpponent(getCurrentFrame(currentFrameOpponent, opponent?.games[currentGame]?.score))

    },[squad])


  useEffect(()=> {
    if (currentFrameOwner === currentFrameOpponent || !opponent) setTurn(owner)
    if (currentFrameOwner > currentFrameOpponent && opponent ) setTurn(opponent)
    setCameras({
      cam1: turn?.camerasId[0],
      cam2: turn?.camerasId[1],
      cam3: turn?.camerasId[2]
    })
  }, [currentFrameOpponent, currentFrameOwner])

  useEffect(()=>{
    setCameras({
      cam1: turn?.camerasId[0],
      cam2: turn?.camerasId[1],
      cam3: turn?.camerasId[2]
    })
  },[turn])

  const data = competitors[0]?.games[0]?.score || []
  const data2 = competitors[1]?.games[0]?.score || []

  const avgOwner = +owner?.avgScore
  const avgOpponent = +opponent?.avgScore
  let totalOwner = owner?.games[currentGame]?.score[currentFrameOwner - 1]?.score
  let hdcOwner =  totalOwner + owner?.hdc

  let totalOpponent = opponent?.games[currentGame]?.score[currentFrameOpponent - 1]?.score
  let hdcOpponent = totalOpponent + opponent?.hdc

  if (currentFrameOwner === 0 && owner?.games[currentGame]?.score[9]?.score) {
    totalOwner = owner?.games[currentGame]?.score[9]?.score
    hdcOwner = totalOwner  + owner?.hdc
  } else if (currentFrameOwner === 0 && !owner?.games[currentGame]?.score[9]?.score){
    totalOwner = owner?.games[currentGame]?.score[0]?.score
    hdcOwner = totalOwner  + owner?.hdc
  }

  if (currentFrameOpponent === 0 && opponent?.games[currentGame]?.score[9]?.score){
    totalOpponent = opponent?.games[currentGame]?.score[9]?.score
    hdcOpponent = totalOpponent + opponent?.hdc
  } else if (currentFrameOpponent === 0 && !opponent?.games[currentGame]?.score[9]?.score){
    totalOpponent = opponent?.games[currentGame]?.score[0]?.score
    hdcOpponent = totalOpponent + opponent?.hdc
  }


  if (hdcOpponent > 300) {
    hdcOpponent = 300
  }

  if (hdcOwner > 300) {
    hdcOwner = 300
  }

  const goBattlesList = () => {
    lazySocket.socket?.emit('leave-squad', squad.id)
    dispatch(clearSquadScore())
    navigate('/battles', { replace: true })
  }
  const flagOwner = owner?.country_live.toLowerCase()
  const flagOpponent = opponent?.country_live.toLowerCase()

  console.log("FLAG-OWNER", flagOwner)
  console.log("FLAG-OPPONENT")

  return (
    <div className="game-screen">
      <header className="game-screen-header">
        <div className="game-header flex-center">
          {/*<Link to="/battles" >*/}
            <div className="header-btn flex-center" onClick={ () => goBattlesList() }>
              <span>Back to "My Battle"</span>
            </div>
          {/*</Link>*/}
          <div className="lobby-info flex-center">
            <h2>#{squad?.sqaudid || ''} {squad?.name || ''} <span>Game - {squad?.competitors[0]?.games.length}</span></h2>
          </div>
        </div>
      </header>

      <main>
        <div className="game-bloc">
          <div className="test">
            <div className="game-camera">
              {fetch? <Spinner/> : <ReactPlayer
                url={`https://video.tenpincam.com:8081/cam${cameras.cam1}-1/index.m3u8?token=localhost`}
                playing
                muted={true}
                width="100%"
                height="100%"
              />}
            </div>
            <div className="game-camera">
              {fetch? <Spinner/> : <ReactPlayer
                url={`https://video.tenpincam.com:8081/cam${cameras.cam2}-1/index.m3u8?token=localhost`}
                playing
                muted={true}
                width="100%"
                height="100%"
              />}
            </div>
          </div>

          <div className="test">
            <div className="game-camera">
              {fetch? <Spinner/> : <ReactPlayer
                url={`https://video.tenpincam.com:8081/cam${cameras.cam3}-1/index.m3u8?token=localhost`}
                playing
                muted={true}
                width="100%"
                height="100%"
              />}
            </div>

            <div className="game-info">
              <div className="game-info-bloc">
                <div className={ turn === owner ? "shotOwner" : "shotOpponent" }>
                  {/*First player*/}
                  <div className="user-avatar">
                    <div className="user-avatar-info">
                      <div>{owner?.firstName} {owner?.lastName}</div>
                      <div>{owner?.login}</div>
                      {/*<div>* * * * * 3.0</div>*/}
                    </div>
                    <div className="user-avatar-img">
                      <img src={`${config.FETCH.img}/${owner?.photo}`} />
                      <svg className="circle" viewBox="0 0 70 70">
                        <circle cx="50%" cy="50%" r="48%" stroke="#F65932" fill="transparent" strokeWidth="3"/>
                      </svg>
                      <div className="user-avg-score">
                        {avgOwner.toFixed()}
                      </div>
                      <div className="user-flag">
                        <img src={countryFlags.default[flagOwner]} alt=""/>
                      </div>
                    </div>
                    {opponent ?
                      <div className="games-win">{owner?.gameswin} </div>
                      :
                      <div className="games-win">{hdcOwner}</div>
                    }
                  </div>
                  {/*Second player*/}
                  {opponent && <div className="user-avatar">
                    <div className="user-avatar-info">
                      <div>{opponent?.firstName} {opponent?.lastName}</div>
                      <div>{opponent?.login}</div>
                      {/*<div>* * * * * 3.0</div>*/}
                    </div>
                    <div className="user-avatar-img">
                      <img src={`${config.FETCH.img}/${opponent?.photo}`}/>
                      <svg className="circle" viewBox="0 0 70 70">
                        <circle cx="50%" cy="50%" r="48%" stroke="#35C844" fill="transparent" strokeWidth="3"/>
                      </svg>
                      <div className="user-avg-score">
                        {avgOpponent.toFixed()}
                      </div>
                      <div className="user-flag">
                        <img src={countryFlags.default[flagOpponent]} alt=""/>
                      </div>
                    </div>
                    <div className="games-win2">:{opponent?.gameswin}</div>
                  </div>}
                </div>

                {/*First player*/}
                <div className="score-table">
                  <div className="score-table-line">
                    {data.map(frame => <Score frame={frame} key={frame.framenumber}/>)}
                    <div className="score-table-item table-item-total">
                      <div>TOTAL</div>
                      <div>{totalOwner}</div>
                      <div>{hdcOwner}</div>
                    </div>
                  </div>
                </div>
                {/*<Link to="/battles">*/}
                {/*Second player*/}
                {competitors[1] && <div className="score-table">
                  <div className="score-table-line">
                    {data2.map(secondFrame => <SecondScore secondFrame={secondFrame} key={secondFrame.framenumber}/>)}
                    <div className="score-table-item table-item-total2">
                      <div>TOTAL</div>
                      <div>{totalOpponent}</div>
                      <div>{hdcOpponent}</div>
                    </div>
                  </div>
                </div>}
                {/*<Link to="/battles">*/}
                <div className="back-to-battles" onClick={ () =>  goBattlesList() }>
                  <div>Back</div>
                </div>
                {/*</Link>*/}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
};


export default GameScreen;
