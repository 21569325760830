import {Link} from "react-router-dom";

const SecondScore = ({secondFrame}) => {
  return (
    <div className="score-table-item-2">
      <div>{secondFrame.framenumber}</div>
      <div className="score-table-shoots">
        {secondFrame.shot1 === -1 ? '' : secondFrame.shot1 === 0 ? '- ' : secondFrame.shot1 === 10 ? 'X ' : secondFrame.shot1 + ' '}
        {secondFrame.shot2 === -1 ? '' : secondFrame.shot2 === 0 ? '- ' : secondFrame.shot1 + secondFrame.shot2 === 10 ? '/ ' : secondFrame.shot2 === 10 ? 'X ' : secondFrame.shot2 + ' '}
        {secondFrame.shot3 === -1 ? '' : secondFrame.shot3 === 0 ? '- ' : secondFrame.shot2 + secondFrame.shot3 === 10 ? '/ ' : secondFrame.shot3 === 10 ? 'X' : secondFrame.shot3}
      </div>
      <div>{secondFrame.score}</div>
    </div>
  )
};

export default SecondScore;