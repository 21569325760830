import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store, persistor} from "./lib/store";
import {PersistGate} from "redux-persist/integration/react";
import {BrowserRouter, Link} from "react-router-dom";


if (process.env.NODE_ENV === 'production'){
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

console.log('test')

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/*<BrowserRouter basename={'/'}>*/}
      {/*  <Link to = 'login'/>*/}
      {/*  <Link to = 'battles'/>*/}
      {/*  <Link to = 'register'/>*/}
        <React.StrictMode>
          <App />
        </React.StrictMode>
      {/*</BrowserRouter>*/}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
