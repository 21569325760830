import {applyMiddleware, combineReducers, createStore} from "redux";
import battleListReducer from "./reducers/battleList.reducer";
import createSagaMiddlware from 'redux-saga'
import {battleListWatcher} from "./saga";
import userReducer from "./reducers/User.reducer";
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore} from "redux-persist";
import {composeWithDevTools} from "redux-devtools-extension";
import squadReducer from "./reducers/Squad.reducer";



const reducers = combineReducers({
  battleList: battleListReducer,
  user: userReducer,
  squad: squadReducer
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddlware = createSagaMiddlware()

const logger = (store) => (next) => (action) => {
  console.log('ACTION', action.type, action.payload)
  next(action);
}

const composeEnhancers = composeWithDevTools({
  actionsBlacklist: [],
})

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddlware, logger)),
);

const persistor = persistStore(store, null, () => {
  const state = store.getState();
});

// раскомментировать, если нужно принудительно разлогинить
// setTimeout(() => store.dispatch({ type: 'USER_LOGOUT' }), 5000); //вместо USER_LOGOUT добавить своё
// persistor.purge()

sagaMiddlware.run(battleListWatcher, store)

export { persistor, store, storage };
