

const config = {
  FETCH:{
    socketUrl: 'https://tenpincam.com:4004/watcher',
    fullUrl: 'https://watch.tenpincam.com',
    img: 'https://tenpincam.com',
  },
}

module.exports = {
  config,
}

